import React, {useCallback, useEffect, useState} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {
  IActivationCodeForm,
  initialValue,
  validationSchema,
} from 'Admin/AdminDashboard/components/ActivationCodes/ActivationCodeForm/validation';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminActivationCodes';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {IAppState} from 'Common/store/IAppState';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {FormType} from 'Common/constants/FormType';
import {
  convertActivationCodeFormToActivationCodeRequest,
  convertActivationCodeToActivationCodeForm,
} from './converters/activationCode';
import {InputField, SelectField} from 'Common/components/FormFields';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import Loading from 'Loading/components/Loading';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {sortByName} from 'Common/helpers/sortByName';
import {castToOption, getStringKeysOption} from '../../../../../Common/helpers/OptionHelper';
import {AnimalType} from 'Common/constants/AnimalType';
import {IPackageSimple, PackageStatus} from '../../../../../Dictionaries/models/IPackageSimple';

const formHeaderByType: Record<FormType.create | FormType.edit | FormType.createBased, string> = {
  create: 'Add activation code',
  edit: 'Edit activation code',
  createBased: 'Add activation code',
};

const buttonHeaderByType: Record<FormType.create | FormType.edit | FormType.createBased, string> = {
  create: 'Add activation code',
  edit: 'Save code',
  createBased: 'Add activation code',
};

interface IExternalDictionaries {
  packagesDictionary: ISimpleDictionary<IPackageSimple>;
}

interface IExternalProps extends IExternalDictionaries {
  activationCodeId?: number;
  type: FormType;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IActivationCodeForm> & OuterProps;

const DROPDOWN_ITEM_HEIGHT = 37;

const ActivationCodesFormLayout = (props: AllProps) => {
  const {setStatus, isSubmitting, values} = props;
  const {activationCodeId, type} = props;
  const {
    activationCode,
    packages,
    packagesLoading,
    getActivationCodeDetails,
    resetActivationCodeDetails,
    packagesDictionary,
    activationCodeLoading,
    activationCodesCreating,
    activationCodeUpdating,
    onSuccess,
  } = props;

  const {
    actions: {getItems: getProducts},
  } = packagesDictionary;

  useOnSuccessCommunication(activationCodesCreating, onSuccess);
  useOnSuccessCommunication(activationCodeUpdating, onSuccess);

  const [noProducts, setNoProducts] = useState<boolean>(false);

  const errorInfo =
    packagesLoading.error ||
    activationCodeLoading.error ||
    activationCodesCreating.error ||
    activationCodeUpdating.error;

  const isLoading = [packagesLoading, activationCodeLoading].some((value) => value.isRequesting);

  useEffect(() => {
    const {packageId} = values;
    const noProduct = packageId === 0;
    setNoProducts(noProduct);
  }, [values]);

  const isValid = props.submitCount === 0 || props.isValid;

  const header = formHeaderByType[type];
  const saveButtonHeader = buttonHeaderByType[type];

  useEffect(() => {
    const commonError = getCommonErrors(errorInfo);
    commonError && setStatus(commonError);
  }, [errorInfo, setStatus]);

  useEffect(() => {
    const fetchData = async () => {
      await getProducts();
      if (activationCodeId) {
        await getActivationCodeDetails(activationCodeId);
      } else {
        await resetActivationCodeDetails();
      }
    };

    fetchData();

    return resetActivationCodeDetails;
  }, [activationCodeId, getActivationCodeDetails, getProducts, resetActivationCodeDetails, type]);

  const getProductsOptions = useCallback(
    (packages: IPackageSimple[]) => {
      const mappedPackage = [...packages, ...(activationCode != null ? [activationCode.pkg] : [])]
        .filter((x) => !!x)
        .map((x) => (x.status ? x : {...x, status: PackageStatus.Active}));

      return mappedPackage
        .sort(sortByName)
        .map((i) => (i.status !== PackageStatus.Active ? {...i, name: `${i.name} (${i.status?.toUpperCase()})`} : i));
    },
    [activationCode]
  );

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent style={{paddingBottom: 32}}>
          {(isLoading || isSubmitting) && <Loading />}
          <div className="d-flex align-items-end" style={{gap: '8px'}}>
            <div className="w-50">
              <SelectField
                isMulti={false}
                isRequired={true}
                name="animalType"
                label="Animal Type"
                options={getStringKeysOption(AnimalType)}
              />
            </div>
            <div className="w-50">
              <SelectField
                isMulti={false}
                isRequired={true}
                name="packageId"
                label="Package"
                options={castToOption(getProductsOptions(packages))}
                memoized={type === FormType.edit}
                menuPlacement="bottom"
                maxMenuHeight={DROPDOWN_ITEM_HEIGHT * 6}
              />
            </div>
          </div>
          <div className="d-flex align-items-end" style={{gap: '8px', paddingBottom: '160px'}}>
            <div className="w-50">
              <InputField
                isRequired={false}
                id="description"
                name="description"
                type="text"
                label="Description"
                placeholder="Activation Code Description"
              />
            </div>
            <div className="w-50">
              {type === FormType.create && (
                <InputField
                  isRequired={true}
                  id="amount"
                  name="amount"
                  type="text"
                  label="Number of codes to create"
                  placeholder="Number of codes to create"
                />
              )}
              {type === FormType.edit && (
                <InputField name="code" type="text" label="Code" autoComplete="off" disabled={true} />
              )}
            </div>
          </div>
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ModalWindowButton type="submit" disabled={isLoading || !isValid || noProducts} isLoading={isSubmitting}>
            {saveButtonHeader}
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const ActivationCodeForm = withFormik<OuterProps, IActivationCodeForm>({
  mapPropsToValues: ({activationCode}) =>
    activationCode ? convertActivationCodeToActivationCodeForm(activationCode) : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    console.log('Submitting ActivationCodeForm');
    const {type, updateActivationCode, createActivationCodes} = formikBag.props;
    const convertedValues = convertActivationCodeFormToActivationCodeRequest(values);
    const request =
      type === FormType.create || type === FormType.createBased ? createActivationCodes : updateActivationCode;
    await request(convertedValues);
  },
  enableReinitialize: true,
})(ActivationCodesFormLayout);

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {packagesDictionary} = props;
  const {selectors: packageSelectors} = packagesDictionary;

  return {
    activationCode: selectors.selectActivationCodeDetails(state),
    packages: packageSelectors.selectItems(state),
    packagesLoading: packageSelectors.selectCommunication(state, 'itemsLoading'),
    activationCodeLoading: selectors.selectCommunication(state, 'activationCodeDetailsLoading'),
    activationCodesCreating: selectors.selectCommunication(state, 'activationCodesCreating'),
    activationCodeUpdating: selectors.selectCommunication(state, 'activationCodeUpdating'),
  };
};

const mapDispatchToProps = {
  getActivationCodeDetails: actions.getActivationCodeById,
  createActivationCodes: actions.createActivationCodes,
  updateActivationCode: actions.updateActivationCode,
  resetActivationCodeDetails: actions.resetActivationCodeDetailResults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(React.memo(ActivationCodeForm));
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {activePackages} = useDictionaries();

  return <Connected {...externalProps} packagesDictionary={activePackages} />;
};

export default Exported;
