import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {IAppState} from 'Common/store/IAppState';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {Cell, Table} from 'Common/components/Table/Table';
import {IActivationCode} from 'ActivationCode/models/IActivationCode';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';

import {AdminActivationCodesModule} from 'Admin/AdminDashboard/store/adminActivationCodes/adminActivationCodesModule';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminActivationCodes/index';
import {Pagination} from 'Common/components/Controls/index';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Loading from 'Loading/components/Loading';
import {FormType} from 'HorseProfile/components/HorseProfileForm/constants';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import ActivationCodesForm from './ActivationCodeForm/ActivationCodeForm';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {AvatarCell} from '../shared';
import {useUpdateUserModal} from 'Admin/AdminDashboard/helpers/hooks/useUserActions/parts/useUpdateUserModal';
import {ActivationCodeFilters} from './ActivationCodeForm/ActivationCodeFilters';
import {FaFilter} from 'react-icons/fa6';
import {IMenuItemValued} from '../../../../Common/models/IMenuItemValued';
import ActivateCodeForm from './ActivationCodeForm/ActivateCodeForm';
import ActivationCodeActions from './ActivationCodeActions';

enum ActivationCodesActionValue {
  activateActivationCode = 'activateActivationCode',
  EditActivationCode = 'editActivationCode',
  DeactivateActivationCode = 'deactivateActivationCode',
}

const CONFIRM_DEACTIVATE_ACTIVATIONCODE = 'Are you sure you want to remove the ActivationCode?';

const AddActivationCodesButton = styled(PrimaryButton)`
  width: 176px;
  height: 50px;
  margin-left: 25px;
`;

const ShowFiltersButton = styled(PrimaryButton)`
  margin-left: 55px;
`;

const ActivationCodesTable = styled.div`
  margin-top: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const ActivationCodes = (props: AllProps) => {
  const {
    getActivationCodes,
    deactivateActivationCode,
    activationCodesLoading,
    activationCodeDeactivating,
    activationCodeUpdating,
    activationCodes,
  } = props;

  const {
    selectedId,
    setSelectedId,
    openDeleteModal,
    reloadItems,
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    deleteModal,
    searchBar,
    params,
    setRequestParams,
  } = useCommonAdminPageData<IActivationCode>({
    getItems: getActivationCodes,
    deleteParams: {
      communication: activationCodeDeactivating,
      confirmDescription: CONFIRM_DEACTIVATE_ACTIVATIONCODE,
      action: deactivateActivationCode,
    },
    searchBarPlaceholder: 'Search for activation codes by any keyword',
  });

  const [isCreateOrEditActivationCodeOpen, setIsCreateOrEditActivationCodeOpen] = useState(false);
  const [isActivateCodeOpen, setIsActivateCodeOpen] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [formType, setFormType] = useState<FormType>(FormType.create);

  const isRequesting = [activationCodesLoading, activationCodeDeactivating, activationCodeUpdating].some(
    (i) => i.isRequesting
  );

  const handleAddActivationCode = () => {
    setFormType(FormType.create);
    setSelectedId(null);
    setIsCreateOrEditActivationCodeOpen(true);
  };

  const handleEditActivationCode = (id: number) => {
    setFormType(FormType.edit);
    setSelectedId(id);
    setIsCreateOrEditActivationCodeOpen(true);
  };

  const handleActivateCode = (id: number) => {
    setFormType(FormType.activate);
    setSelectedId(id);
    setIsActivateCodeOpen(true);
  };

  const closeCreateOrEditActivationCodeModal = useCallback(() => {
    setIsCreateOrEditActivationCodeOpen(false);
  }, []);

  const closeActivateCodeModal = useCallback(() => {
    setIsActivateCodeOpen(false);
  }, []);

  const onSuccessSubmitActivationCodesForm = useCallback(() => {
    closeCreateOrEditActivationCodeModal();
    reloadItems();
  }, [closeCreateOrEditActivationCodeModal, reloadItems]);

  const onSuccessSubmitActivateCodeForm = useCallback(() => {
    closeActivateCodeModal();
    reloadItems();
  }, [closeActivateCodeModal, reloadItems]);

  const {openUpdateUserModal, updateUserModal} = useUpdateUserModal({onSuccess: reloadItems});

  const isShowPagination = activationCodes.length > 0;

  console.log(activationCodes);

  return (
    <AdminPageLayout>
      {deleteModal}
      {updateUserModal}
      <ModalWindow isOpen={isCreateOrEditActivationCodeOpen} onClose={closeCreateOrEditActivationCodeModal}>
        <ActivationCodesForm
          activationCodeId={selectedId || undefined}
          type={formType}
          onSuccess={onSuccessSubmitActivationCodesForm}
        />
      </ModalWindow>
      <ModalWindow isOpen={isActivateCodeOpen} onClose={closeActivateCodeModal}>
        <ActivateCodeForm activationCodeId={selectedId || undefined} onSuccess={onSuccessSubmitActivateCodeForm} />
      </ModalWindow>
      {showFiltersModal && (
        <ModalWindow isOpen={true} onClose={() => setShowFiltersModal(false)}>
          <ActivationCodeFilters
            filters={params?.filters as string[]}
            onChangeFilters={(filterName: string, filterValue: string | undefined) =>
              setRequestParams({
                ...params,
                filters: {
                  ...params.filters,
                  [filterName]: filterValue,
                },
              })
            }
          />
        </ModalWindow>
      )}
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{searchBar}</div>
        <div>
          <ShowFiltersButton onClick={() => setShowFiltersModal(true)} variant="hollowed">
            <FaFilter /> Filters
          </ShowFiltersButton>
        </div>
        <AddActivationCodesButton onClick={handleAddActivationCode}>+ Add Activation Codes</AddActivationCodesButton>
      </div>
      <ActivationCodesTable className="position-relative">
        {isRequesting && <Loading />}
        <Table<IActivationCode> data={activationCodes} rowKey="id" sorting={sorting} onChangeSorting={changeSorting}>
          <Cell<IActivationCode> header="ID" dataKey="id" render={({id}) => `#${id}`} width="10%" />
          <Cell<IActivationCode>
            header="Description"
            dataKey="description"
            render={({description}) => `${description}`}
            width="10%"
          />
          <Cell<IActivationCode>
            header="Package"
            dataKey="pkg"
            width="10%"
            render={(activationCode) => `${activationCode.pkg?.name}`}
          />
          <Cell<IActivationCode> header="Code" dataKey="code" render={({code}) => code} width="10%" />
          <Cell<IActivationCode> header="Status" dataKey="status" width="10%" render={({status}) => `${status}`} />

          <Cell<IActivationCode>
            header="Owner"
            dataKey="activationCodeRedemption"
            render={({activationCodeRedemption}) =>
              activationCodeRedemption?.redeemer && (
                <AvatarCell
                  type="owner"
                  onLabelClick={() => openUpdateUserModal(activationCodeRedemption.redeemer!.id)}
                  label={`${activationCodeRedemption?.redeemer?.firstName} ${activationCodeRedemption?.redeemer?.lastName}`}
                  // avatarUrl={owner.avatar?.url}
                  profileUrl={`/admin/user/user-profile/${activationCodeRedemption.redeemer!.id}`}
                />
              )
            }
            width="25%"
          />

          <Cell<IActivationCode>
            header="Animal"
            dataKey="activationCodeRedemption"
            width="20%"
            render={({activationCodeRedemption}) => {
              if (!activationCodeRedemption?.animal) return '';
              return activationCodeRedemption.animal?.name;
            }}
          />

          <Cell<IActivationCode>
            header="Source"
            dataKey="productSource"
            width="10%"
            render={({productSource}) => `${productSource}`}
          />
          <Cell<IActivationCode>
            header="Actions"
            align={CellAlign.Right}
            width="10%"
            render={(activationCode) => (
              <ActivationCodeActions
                activationCode={activationCode}
                editCodeHandler={handleEditActivationCode}
                activateCodeHandler={handleActivateCode}
                deleteCodeHandler={openDeleteModal}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={props.pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </ActivationCodesTable>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  activationCodes: selectors.selectActivationCodes(state),
  activationCodesLoading: selectors.selectCommunication(state, 'activationCodesLoading'),
  activationCodeDeactivating: selectors.selectCommunication(state, 'activationCodeDeactivating'),
  activationCodeUpdating: selectors.selectCommunication(state, 'activationCodeUpdating'),
  activationCodeActivating: selectors.selectCommunication(state, 'activationCodeActiviating'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getActivationCodes: actions.getActivationCodes,
  deactivateActivationCode: actions.deactivateActivationCode,
  updateActivationCode: actions.updateActivationCode,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(ActivationCodes);

export default withDynamicModules(Connected, [AdminActivationCodesModule]);
