import {memo, useMemo} from 'react';

import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {IMenuItem} from 'Common/components/DropDown/common/types';
import AdminActions from 'Admin/AdminDashboard/components/shared/AdminActions/AdminActions';
import {IActivationCode} from 'ActivationCode/models/IActivationCode';
import {ActivationCodeStatus} from 'ActivationCode/constants/ActivationCodeStatus';

const iconProps = {color: ColorPalette.red7};

const commonMenuItems: IMenuItem[] = [
  {value: 'activateCode', label: 'Activate code', icon: {name: IconName.CheckMark, ...iconProps}},
  {value: 'editCode', label: 'Edit code', icon: {name: IconName.Edit, ...iconProps}},
  {value: 'deleteCode', label: 'Delete code', icon: {name: IconName.Delete, ...iconProps}},
];

interface IExternalProps {
  activationCode: IActivationCode;
  activateCodeHandler: (activationCodeId: number) => void;
  editCodeHandler: (activationCodeId: number) => void;
  deleteCodeHandler: (activationCodeId: number) => void;
}

type AllProps = IExternalProps;

const ActivationCodeActions = (props: AllProps) => {
  const {id, status} = props.activationCode;

  const {activateCodeHandler, editCodeHandler, deleteCodeHandler} = props;

  const isRedeemded = status === ActivationCodeStatus.Redeemed;
  const isInactive = status === ActivationCodeStatus.Inactive;

  const mapAvailableByMenuItem = useMemo(
    () => ({
      editCode: true,
      activateCode: !isInactive && !isRedeemded,
      deleteCode: true,
    }),
    [isRedeemded, isInactive]
  );

  const actionsHandler = {
    editCode: () => editCodeHandler(id),
    activateCode: () => activateCodeHandler(id),
    deleteCode: () => deleteCodeHandler(id),
  };

  const onSelectHandler = (type: keyof typeof actionsHandler) => actionsHandler[type]();

  const shownMenuItems = useMemo(
    () =>
      commonMenuItems
        .map((item) => ({...item, availabilityKey: mapAvailableByMenuItem[item.value]}))
        .filter((item) => item.availabilityKey),
    [mapAvailableByMenuItem]
  );

  return <AdminActions menuItems={shownMenuItems} onSelectHandler={onSelectHandler} />;
};

export default memo(ActivationCodeActions);
export type ActivationCodeActionsProps = IExternalProps;
